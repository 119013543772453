<template>
  <LSection>
    <template #body>
      <header class="heading1 !mb-2">
        <slot name="title"></slot>
        <hr class="gradientLine" />
      </header>
      <div
        class="flex flex-col k1:flex-row k1:gap-12 gap-4 mt-12 bg-slate-100 p-4 k1:p-8"
      >
        <slot name="description"></slot>
        <slot name="image"></slot>
      </div>
      <!--      Replace this with all projects from the specific area-->
      <!--      <CardsNews></CardsNews>-->
    </template>
  </LSection>
</template>

<script>
// import CardsNews from "@/components/News/CardsNews.vue";
import LSection from "@/components/layout/LSection.vue";

export default {
  name: "LImpact",
  components: { LSection },
};
</script>

<style scoped></style>
